import React from 'react';
import { useWhichChatToShow } from '@integrations/gladly/useGladlyChatHooks';
import KustomerComponent from '@components/Kustomer/Kustomer.Component';
import GladlyComponent from '@integrations/gladly/Gladly/Gladly.Component';

/**
 * Need a simple wrapper to display the proper chat
 * @param props
 * @returns {React.JSX.Element | null}
 * @constructor
 *
 * note: using dynamic components here breaks the build.
 */
const Chat = (props: any) => {
  const {isGladlyChat,isChatReady} = useWhichChatToShow();

  if (!isChatReady) return null;

  return (
    isGladlyChat
      ? <GladlyComponent pageConfig={props.pageConfig || {}} />
      : <KustomerComponent pageConfig={props.pageConfig || {}} />
  )
}

export default Chat;
