/**
 * All layouts will share common traits. This is the base layout that
 * will consolidate so we don't have repetition
 * @constructor
 */
/* Libraries */
import React from 'react';
import cn from 'classnames';

/* Components */
import NoticeStack from '@components/UI/NoticeStack/NoticeStack';
import Sidebar from '@components/Sidebar/Sidebar';
import LazyComponent from '@components/LazyComponent';
import Snackbar from '@components/UI/Snackbar';
import PinnedContainer from '@components/UI/PinnedContainer/PinnedContainer';
import StandardHeaderWrapper from '@components/PageLayouts/Header/Standard/Header.Container';
import Chat from '@integrations/gladly/Gladly/Chat';

/* Types & Constants */
import {Children} from 'types/base.types';
import {COMPONENT_POSITION} from 'types/component.types';
import {PageConfigProps} from 'types/page.types';
import {PartnerBaseAttributes} from '@integrations/partners/partners.types';

/* Styles */
import styles from './standardlayout.module.scss';

export type BaseLayoutProps = {
  Header: React.ElementType;
  Footer?: any; // Promise<React.ComponentType<any>>;
  children: Children;
  pageConfig: PageConfigProps['pageConfig'];
  partner?: PartnerBaseAttributes;
};

/*
 * THIS IS THE BASE LAYOUT FOR ALL LAYOUTS
 * - You get the header/footer/sidenav/notice stack
 * - kustomer module
 * - base site wide portal
 *
 * We don't know yet how we are gonna implement partners/cobrands. So, can't really build
 * out the infrastructure for it until we sort this out. Need to settle on a strategy.
 * */
const BaseLayout = (props: BaseLayoutProps) => {
  const {pageConfig, Header, Footer, children, partner} = props;
  /* Hooks */
  /* Use this if we want to have a global loader for fetched items in standard layout */
  // const fetching = useIsFetching(); Can filter on "types" of queries etc.. useIsFetching(['user'])
  // const fetching = useIsMutating(); Can filter on "types" of queries etc.. useIsMutating(['user'])

  const mainWrapperClasses = cn({
    [styles.mainWrapper]: true,
    [styles.hideFooter]: false, // !showFooter,
    [styles.bgNavy050]: false,
    [styles.bgNavy050Mobile]: false,
  });

  return (
    <>
      <NoticeStack />
      <Sidebar />
      <div className={mainWrapperClasses} id="ht_main_app_wrapper">
        <StandardHeaderWrapper pageConfig={pageConfig} partner={partner}>
          <Header pageConfig={pageConfig} />
        </StandardHeaderWrapper>
        <Snackbar type={COMPONENT_POSITION.fixed} />
        <div className={styles.mainContentWrapper}>{children}</div>
        {
          /* pageConfig.footer && Footer */ true && (
            <div className={styles.footerWrapper}>
              <LazyComponent minHeight="100px">
                <Footer partner={partner} />
              </LazyComponent>
            </div>
          )
        }
      </div>
      {/* Sitewide modals for common layout */}
      <div id="react-portal-placement" />
      <PinnedContainer />
      {/* Chat */}
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <Chat pageConfig={props.pageConfig || {}} />
    </>
  );
};

export default BaseLayout;

