// @ts-nocheck
import React, {useEffect, useRef} from 'react';
import {useRouter} from 'next/router';

/* Store Contexts */
import useUserCache from '@store/queries/user/useUser.cache';
import useCartCache from '@store/queries/cart/useCart.cache';

/* Class */
import HTGladlyChat from './Gladly.methods';

/* Components */
import GladlyPlaceholder from './Gladly.Placeholder';
import {BaseLayoutProps} from '@components/PageLayouts/BaseLayoutContent/Layout.Base';

/* Utils */
import {noop} from '@utils/event';
import {isBrowser} from '@utils/ui';
import {sleep} from '@utils/helpers';
import {isProduction} from '@utils/environment';

/* Integrations */
import {SCRIPT_LOAD_TIME_CHAT} from '@integrations/integrations.constants';

/*
 * We could just copy from client, but the issue here is we need to initialize the download after user
 * interaction. So, we need listeners to facilitate this.
 *
 * params: hideChat - this boolean is passed on the static generated page in the getLayout
 * */
type GladlyChatProps = {
  hideChat?: boolean;
  pageConfig?: BaseLayoutProps['pageConfig'];
};

type TKLoadGladlyProps = {setOpen: boolean};

/**
 * 1. Load Gladly after long delay
 *    - If user interacts with icon, load immediately
 *
 *    Note: Gladly is too detrimental to load times, so we need to delay it until
 *          after Segment & accessibe. It's lame, I agree.
 * @param {GladlyChatProps} props
 * @returns {React.JSX.Element | null}
 * @constructor
 */
const GladlyComponent = (props: GladlyChatProps) => {
  /* Local State */
  const [showPlaceholder, setShowPlaceholder] = React.useState<boolean>(true);

  /* Destructure props */
  const {hideChat = false} = props;

  /* Hooks */
  const {query = {}, asPath} = useRouter();
  const enqueueRef = useRef<BaseAnyFunction[]>([]);
  const user = useUserCache();
  const cart = useCartCache();
  const refScriptIsLoaded = useRef(false);
  const intervalRef = useRef(0);

  /* Constants */
  const initChatOpen = query.chat || query.openCustomerService;

  /* Methods */
  const loadEnqueue = () => {
    /* If we qued any methods fire them */
    enqueueRef.current.forEach(m => m());
  };

  const loadUser = () => {
    if (user?.email) {
      HTGladlyChat?.identify(null, {
        email: user.email,
      });
    }
  };

  /* Lets see if Gladly is already in the Window */
  const isGladlyInWindow = () => {
    if (!isBrowser()) return false;

    if ('Gladly' in window) {
      return true;
    }

    return false;
  };

  /* eslint-disable */
  const gladlyReadyCallback = ({setOpen}: TKLoadGladlyProps = {setOpen: false}) => {
    const env = isProduction() ? 'PROD' : 'STAGING';
    (function (c, n, r, t) {
      /* This sets the gladly config. We want to set it before Gladly loads. When it loads, it calls the callback. */
      if (!c[r]) {
        var i,
          d,
          p = [];
        (d = 'PROD' !== t && t ? ('STAGING' === t ? 'https://cdn.gladly.com/chat-sdk/widget.js' : t) : 'https://cdn.gladly.com/chat-sdk/widget.js'),
          (c[r] = {
            init: function () {
              i = arguments;
              var e = {
                then: function (t) {
                  return p.push({type: 't', next: t}), e;
                },
                catch: function (t) {
                  return p.push({type: 'c', next: t}), e;
                },
              };
              return e;
            },
          }),
          (c.__onHelpAppHostReady__ = function (t) {
            if ((delete c.__onHelpAppHostReady__, ((c[r] = t).loaderCdn = d), i))
              for (var e = t.init.apply(t, i), n = 0; n < p.length; n++) {
                var a = p[n];
                e = 't' === a.type ? e.then(a.next) : e.catch(a.next);
              }
          }),
          (function () {
            try {
              var t = n.getElementsByTagName('script')[0],
                e = n.createElement('script');
              e.id = 'gladlyScript';
              (e.async = !0), (e.src = d + '?q=' + new Date().getTime()), t.parentNode.insertBefore(e, t);
            } catch (t) {}
          })();
      }
    })(window, document, 'Gladly', env);
    /* eslint-enable */

    const checkLoaded = setInterval(async () => {
      intervalRef.current += 1;
      /* 1. We identified an un-initialized Gladly App. */
      if (isGladlyInWindow() && 'init' in window.Gladly && !('getUser' in window.Gladly)) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        initializeGladlyChat({setOpen});
      }

      /**
       * Silly thing to do, but we need to initialize the chat after a certain time.
       * Like I mentioned, this Gladly is slightly buggy. The 100 is arbitrary. 30 seconds,
       * if nothing happens, lets try to deal with it.
       */
      if (intervalRef.current === 100 && !refScriptIsLoaded.current) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        initializeGladlyChat({setOpen});
      }

      /* 2. We identified an already loaded & initialized Gladly App. */
      if (refScriptIsLoaded.current) {
        /* 3. Omit the "availability" ui for now
         const availability = window.Gladly.getAvailability();
        */
        setTimeout(() => {
          clearInterval(checkLoaded);
          setShowPlaceholder(false);
          intervalRef.current = 0;
          /* This auto-opens if user has a chat reference in url */
          if (initChatOpen || setOpen) {
            window.Gladly.show?.();
          }
        }, 1000);
      }
    }, 300);
  };

  /**
   * This sets up the chat initialization.
   * I have to say Gladly is flaky. It's not always ready when it says it is.
   * @param {boolean} setOpen
   */
  const initializeGladlyChat = ({setOpen}: TKLoadGladlyProps = {setOpen: false}) => {
    HTGladlyChat.start({
      callBack: () => {
        loadUser();
        loadEnqueue();
        /* Our script is loaded */
        refScriptIsLoaded.current = true;
        /* This auto-opens if user has a chat reference in url */
        if (initChatOpen || setOpen) {
          window.Gladly.show?.();
        }
      },
      force: true,
    });
  };

  const loadGladlyScript = ({setOpen}: TKLoadGladlyProps = {setOpen: false}) => {
    if (setOpen && isGladlyInWindow() && refScriptIsLoaded.current) {
      window.Gladly.show?.();
    }

    /* Load the Gladly Script */
    gladlyReadyCallback({setOpen});
  };
  /*
   * Lets load the gladly chat script
   * */
  const loadGladly =
    (predicate: () => boolean = () => true) =>
    () => {
      const fireGladly = () => {
        if (predicate()) {
          loadGladlyScript({setOpen: !!initChatOpen});
        }
      };

      const executeWithTimer = () => {
        const timer = initChatOpen ? 1500 : SCRIPT_LOAD_TIME_CHAT;
        setTimeout(() => {
          fireGladly();
        }, timer);
      };

      /* Check if document is already loaded */
      if (document.readyState === 'complete') {
        executeWithTimer();
      } else {
        /* If not loaded, add the event listener */
        window.addEventListener('load', executeWithTimer);
      }
    };

  const addAndRemoveListeners = (type: string) => {
    window[`${type}EventListener`](
      'loadAndOpenChat',
      () => {
        loadGladlyScript({setOpen: true});
      },
      {once: true},
    );
  };

  /*
   * Delete any Kustomer items that might be showing or existing
   */
  useEffect(() => {
    /* Delete Kustomer if it exists */
    const kPlaceholder = document.getElementById('kustomerPlaceholderImage');
    const kIframe = document.getElementById('kustomer-ui-sdk-iframe');
    const kScript = document.getElementById('kustomerScript');
    if (window?.Kustomer) {
      window.Kustomer.close?.();
      delete window.Kustomer;
      kPlaceholder?.remove();
      kIframe?.remove();
      kScript?.remove();
    }
  }, []);

  /*
   * Set up and remove listeners
   * */
  useEffect(() => {
    if (!hideChat) {
      addAndRemoveListeners('add');

      /*
        IF we fall within a mobile overrride.
        If firing from "load" - in dev mode, it won't happen as the "load"
        is registered after it was called. Fine with a 'build'.
      */
      sleep(0).then(() => {
        loadGladly()();
      });

      /* Remove event listeners */
      return () => addAndRemoveListeners('remove');
    }

    return noop;
  }, [cart, hideChat, asPath, initChatOpen]);

  /* Gladly chat loaded and dropping it's own icon */
  if (!showPlaceholder) return null;

  return <GladlyPlaceholder loadGladlyScript={loadGladlyScript} />;
};

export default GladlyComponent;
